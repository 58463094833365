import React from 'react'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import Showdown from 'showdown'


class ColumnAlternating extends React.Component {
  render() {
    const converter = new Showdown.Converter()

    return (
      <MDBRow className={`mb-3 align-items-top ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >

        <MDBCol lg={this.props.textCol ? this.props.textCol : '6'} className="mt-5 mt-md-3 mt-lg-0 mb-4">
        {this.props.title && (
          <h3 className={ this.props.titleClass ? this.props.titleClass : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium' } >
            {this.props.title}
          </h3>
          )}
          {this.props.subtitle && (
            <h4 className="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium">
              {this.props.subtitle}
            </h4>
          )}
          <div
            className="font-w-400 text-medium divlink pr-lg-3"
            dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.description) }}
          />
          
          {(this.props.link && this.props.colour) ? (
            this.props.link.charAt(0) == '/' ? (
              <Link
                to={this.props.link}
                className={`mt-4 ${this.props.colour}`}
                aria-label={this.props.title}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            ) : (
              <a
                href={this.props.link}
                className={`mt-4 ${this.props.colour}`}
                target="_blank"
                rel="noopener"
                aria-label={this.props.title}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </a>
            )
          ) : (
            <div className="mt-4">
              <>
              {this.props.linktext &&
                <Link
                  to={this.props.link}
                  className="text-medium effect"
                  aria-label={this.props.title}
                >
                  {this.props.linktext}
              </Link>
              }
            </>
           </div>
          )}      
        </MDBCol>
        <MDBCol lg={this.props.imageCol ? this.props.imageCol : '6'} className="text-lg-center text-sm-left order-first order-md-1">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className={this.props.imageClass ? this.props.imageClass: "rounded innovation-box"}
          />
        </MDBCol>        
      </MDBRow>
    )
  }
}

export default ColumnAlternating
