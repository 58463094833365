import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import SolutionText from '../components/solutionText'
import SolutionTextImage from '../components/solutionTextImage'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import SolutionBulletsVertical from '../components/solutionBulletsVertical'
import Card from '../components/card'
import Cta from '../components/cta'
import CardCompanies from '../components/cardCompanies'
import AltSection from '../components/columnAlternatingPartner'

library.add(fat)

const Partners = ({ data, location }) => {
  const post = data.markdownRemark
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"  

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>

            <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.openingpara,
                  }}
                />
                <div
                  className="mt-3 richtext divlink pb-5"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>             
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section.map((sections, index) => (
              <Fragment key={index}>
                {sections.type === 'text' && (
                  <SolutionText
                    key={index}
                    data={sections}
                    sectionClass={sections.class}
                    sectionCenter={sections.center}
                  />
                )}
                {sections.type === 'bullets vertical' && (
                  <SolutionBulletsVertical
                    key={index}
                    data={sections}
                    sectionClass={sections.class}
                  />
                )}
                {sections.type === 'bullets horizontal' && (
                  <SolutionBulletsHorizontal
                    key={index}
                    data={sections}
                    sectionClass={sections.class}
                    sectionCenter={sections.center}
                  />
                )}
                {sections.type === 'text and image' && (
                  <SolutionTextImage
                    key={index}
                    data={sections}
                    sectionClass={sections.class}
                    placement={sections.placement}
                  />
                )}
              </Fragment>
            ))}
          </>
        )}

        {post.frontmatter.partner.map((sections, index) => (
          <section className="bg-light-blue" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pr-5 pb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {sections.title}
                    <span id="global-distributor" className="anchor">
                        &nbsp;
                    </span>  
                  </h2>
                  {sections.subtitle && (
                    <p className="text-medium pt-5">{sections.subtitle}</p>
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {sections.feature && (
                    <CardCompanies data={sections.feature} btnId={btnId} />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}

        {post.frontmatter.tech &&
          <section className="bg-white">
            <MDBAnimation type="fadeIn" duration="1587ms">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <h2 className="font-alt font-w-700 letter-spacing-1 pb-5 title-xs-medium title-large" >
                      {post.frontmatter.tech.title}
                      <span id="technology-partners" className="anchor">
                        &nbsp;
                      </span>                    
                    </h2>
                    <p className="text-medium">{post.frontmatter.tech.description}</p>
                  </MDBCol>
               </MDBRow>
            </MDBContainer>
              <MDBContainer>
                  {post.frontmatter.tech.type.map((partnertype, index) => (
                    <>
                    <h3 className="font-alt font-w-700 pt-5 pb-5 pb-md-3 letter-spacing-1 title-xs-medium title-medium" >
                      {partnertype.title}
                    </h3>
                    
                    {partnertype.partner.map((partner, index) => (
                      <AltSection
                      key={index}
                      textCol={'9'}
                      imageCol={'3'}
                      title={partner.title}
                      description={partner.description}
                      image={partner.image.childImageSharp.gatsbyImageData}
                      imageClass="rounded"
                      alt={partner.alttext}
                      placement={partner.placement}
                    />
                    ))}
                    </>
                  )                 
                  )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        } 

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>
                <p className="text-medium">
                  {post.frontmatter.helpfulresources.subtitle}
                </p>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="7.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={
                                helpfulres.image.childImageSharp.gatsbyImageData
                              }
                              alt={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={
                                helpfulres.link
                                  ? helpfulres.link
                                  : helpfulres.document.publicURL
                              }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}
        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </main>
    </Layout>
  )
}
export default Partners 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "Partners" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        ctatext
        ctaslug
        openingpara 
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          intro
          description
          class
          type
          link
          linktext
          alttext
          placement
          document {
            publicURL
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            subtitle
            icon
            description
            link
            linktext
            alttext
            colour
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        partner {
          title
          subtitle
          feature {
            title
            subtitle
            description
            tel
            email
            icon
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            link
            linktext
            placement  
          }
        }
        tech {
          title
          description
          type {
            title
            partner {
              placement
              description
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 90)
                }
              }
              alttext
            }
          }
        }    
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`